import { saveToken } from '@/lib/cookies';
import { Menu } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import '/node_modules/flag-icons/css/flag-icons.min.css';

const LanguageSwitch = () => {
  const { t } = useTranslation(['common']);
  const router = useRouter();

  const [language, setLanguage] = useState<any>();

  const handleLanguageChange = useCallback(
    (locale: string) => {
      // router.push('/', undefined, { locale, shallow: true }).then(() => {
      //   // router.reload();
      //   router.replace(router.asPath, );
      // });

      saveToken('locale', locale, 86400 * 365);
      location.href = `/${locale}`
      // router.replace('/', undefined, { locale }).then(() => {
      //   router.reload();
      // });
    },
    [router]
  );

  const options = useMemo(() => {
    return [
      {
        id: 'vi',
        text: t('common:i18n.vietnamese'),
        className: 'fi fi-vn',
      },
      {
        id: 'en',
        text: t('common:i18n.english'),
        className: 'fi fi-us',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (options) {
      setLanguage(options.find(({ id }) => id === router.locale));
    }
  }, [options, router.locale]);

  const changeLanguage = (id) => {
    if (language && language.id !== id) {
      handleLanguageChange(id);
    }
  };

  return (
    <div className="relative z-50">
      <Menu>
        <Menu.Button className="flex h-8 flex-row items-center gap-2 rounded-md sm:bg-slate-700 px-2.5 uppercase text-slate-700 sm:text-slate-100 dark:text-slate-100 border border-slate-400 sm:border-none">
          <span className={language?.className}></span>
          <span className="text-xs font-semibold">{language?.id}</span>
        </Menu.Button>
        <Menu.Items
          as="ul"
          className="absolute z-50 flex flex-col gap-2 py-2 mt-1 text-gray-500 bg-white rounded-sm shadow-md sm:right-0 w-36 dark:bg-gray-700 dark:text-gray-400"
        >
          {options.map(({ id, text, className }) => (
            <Menu.Item key={id}>
              {({ active }) => (
                <button
                  className={`${
                    active && 'bg-blue-500'
                  } flex flex-row items-center gap-2 px-2`}
                  onClick={() => changeLanguage(id)}
                >
                  <span className={className}></span>
                  {text}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default LanguageSwitch;
