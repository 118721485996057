export type ButtonDropdownProps = {
	id: string;
	title: string;
	icon?: string;
	desc?: string;
	href: string;
	type?: 'icon' | 'desc' | 'normal';
	items?: ButtonDropdownProps[];
	childrenUrls?: string[];
};

export type PopOverProps = {
	id: string;
	title: string;
	href?: string;
	childrenUrls?: string[];
	items?: ButtonDropdownProps[];
};

export const headerData: PopOverProps[] = [
	{
		id: 'blog',
		title: 'blog',
		href: '/',
	},
	{
		id: 'onchain_report',
		title: 'onchainReport',
		href: '/on-chain-report',
	},
	{
		id: 'research',
		title: 'research',
		href: '/research',
	},
	{
		id: 'dashboard',
		title: 'dashboard',
		href: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/dashboard`,
		// href: '/dashboard',
	},
	{
		id: 'pricing',
		title: 'pricing',
		href: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}#pricing`,
	},
	{
		id: 'event',
		title: 'event',
		href: '/event',
	},
	{
		id: 'about',
		title: 'about',
		href: '/about',
	},
];
