/* eslint-disable react/display-name */
import cn from 'classnames';
import { Disclosure, Menu } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ButtonDropdown from '@/components/ButtonDropdown';
import { getFirstLetter } from '@/lib/utils';
import { forwardRef } from 'react';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon';
import ThemeSwitch from '@/components/ThemeSwitch';
import LanguageSwitch from '@/components/LanguageSwitch';
import { useTheme } from 'next-themes';

const MyLink = forwardRef((props: any, ref) => {
	const { href, children, ...rest } = props || {};
	return (
		<Link href={href} legacyBehavior>
			<a ref={ref as any} {...rest}>
				{children}
			</a>
		</Link>
	);
});

export default function MobileHeaderMenu({ user, headerData, t, signIn, profileMenu, closeMenu }) {
	const { locale, pathname } = useRouter();
	const { theme } = useTheme();

	return (
		<div className="relative flex h-screen flex-col bg-gray-100 dark:bg-gray-800">
			<div className="overflow-x-none overflow-y-auto bg-gray-700 py-4">
				{!user ? (
					<Link href="#" passHref legacyBehavior>
						<a title="Sign-in">
							<button
								aria-label="button"
								className={cn(
									'active:text-primary-60 md:hover:text-primary-50 ml-4 h-full w-28 flex-auto items-center justify-center rounded-md bg-white px-4 py-1.5 text-sm font-semibold opacity-100 hover:opacity-90  focus-visible:outline-none dark:bg-bg-primary dark:text-gray-100',
								)}
								onClick={() => signIn()}
							>
								{t('common:header.signIn')}
							</button>
						</a>
					</Link>
				) : (
					<div className="relative z-50">
						<Disclosure>
							{({ open, close }) => (
								<>
									<Disclosure.Button className="flex w-full items-center justify-between px-4">
										<div className="flex items-center py-2 text-left text-white">
											<span className="mr-3 inline-flex h-10 w-10 items-center justify-center rounded-full !bg-gray-500">
												<span className="text-lg font-medium leading-none text-white">
													{getFirstLetter(user?.username)}
												</span>
											</span>
											<div className="">
												{user.username}
												<p className="text-xs font-light opacity-70">{user.email}</p>
											</div>
										</div>
										<ChevronRightIcon className={cn('w-4 fill-white text-white', open ? 'rotate-90 transform' : '')} />
									</Disclosure.Button>
									<Disclosure.Panel className="mt-4 px-4 text-gray-500">
										{profileMenu?.map((item) =>
											item.href ? (
												<Link key={item.id} href={item.href ? item.href : '/'} passHref locale={locale} legacyBehavior>
													<a title={item.label}>
														<button
															aria-label="button"
															className={cn(
																{
																	'opacity-100': item.href === pathname,
																},
																'md:hover:text-primary-50 h-full w-full flex-auto items-center justify-center border-t border-slate-600 py-3 text-left text-sm font-medium text-white opacity-90 hover:opacity-100 focus-visible:outline-none dark:text-gray-100',
															)}
															onClick={closeMenu}
														>
															{item.label}
														</button>
													</a>
												</Link>
											) : (
												<button
													className="block w-full border-t border-slate-600 px-0 py-3 text-left text-sm text-white opacity-100 hover:opacity-80 dark:text-gray-100 "
													onClick={item.onClick}
												>
													{item.label}
												</button>
											),
										)}
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					</div>
				)}
			</div>
			<div className="overflow-x-none overflow-y-auto px-4">
				{headerData.map((item) => (
					<div key={item.id}>
						{item.items ? (
							<Disclosure as="nav" className="relative inline-block w-full text-left">
								{({ open }) => (
									<>
										<Disclosure.Button
											className={cn(
												'button-menu text-title24  group inline-flex w-full justify-between px-4 py-3 font-semibold',
												{
													'text-primary-60': item.childrenUrls && item.childrenUrls.includes(pathname),
													'active:text-primary-60 text-neutral-90 md:hover:text-primary-50': !(
														item.childrenUrls && item.childrenUrls.includes(pathname)
													),
												},
											)}
										>
											<span>{t(`common:header.${item.title}`)}</span>
										</Disclosure.Button>
										<Disclosure.Panel className="space-y-2 px-4 py-2">
											{item.items && item.items.map((item1) => <ButtonDropdown key={item1.id} data={item1} />)}
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						) : (
							<Link href={item.href ? item.href : '/'} passHref locale={locale} legacyBehavior>
								<a title={item.title}>
									<button
										aria-label="button"
										className={cn(
											{
												'opacity-100': item.href === pathname,
											},
											'md:hover:text-primary-50 h-full w-full flex-auto items-center justify-center border-b border-slate-300 py-3  text-left text-sm font-medium opacity-90 hover:opacity-100 focus-visible:outline-none dark:border-slate-700 dark:text-gray-100 ',
										)}
									>
										{t(`common:header.${item.title}`)}
									</button>
								</a>
							</Link>
						)}
					</div>
				))}
			</div>

			<div className="overflow-x-none flex items-center justify-start gap-4 overflow-visible px-4 py-3">
				<p className="text-sm font-medium opacity-90">{t('common:i18n.language')}</p>
				<LanguageSwitch />
			</div>

			<div className="overflow-x-none flex items-center justify-start gap-4 overflow-visible px-4 py-3">
				<p className="text-sm font-medium opacity-90">{t('common:theme.appearance')}</p>

				<div className="flex items-center">
					<ThemeSwitch />
					<span>{t(`common:theme.${theme}`)}</span>
				</div>
			</div>
		</div>
	);
}
