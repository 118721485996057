import React from 'react';
import IconComponent from '../IconComponent';

type Props = React.SVGProps<SVGSVGElement>;

export default function ChevronRightIcon({
  height = 24,
  width = 24,
  color = 'inherit',
  ...props
}: Props) {
  return (
    <IconComponent
      height={height}
      width={width}
      color={color}
      path="M8.84715 19.6743L15.7809 12.5431C16.073 12.2429 16.073 11.7579 15.7809 11.4569L8.84715 4.32572C8.4254 3.89143 7.73922 3.89143 7.31672 4.32572C6.89497 4.76002 6.89497 5.46488 7.31672 5.89917L13.2482 12.0004L7.31672 18.1C6.89497 18.5351 6.89497 19.24 7.31672 19.6743C7.73922 20.1086 8.4254 20.1086 8.84715 19.6743"
      {...props}
    />
  );
}
