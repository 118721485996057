import classNames from 'classnames';
import cn from 'classnames';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { BiArrowFromBottom } from 'react-icons/bi';
import JoinTelegramGroupButton from './JoinTelegramGroupButton';

export default function ButtonScrollTop() {
  const [visible, setVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const toggleVisibility = () => {
        if (window.scrollY > window.innerHeight) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      };

      window.addEventListener('scroll', toggleVisibility);

      return () => window.removeEventListener('scroll', toggleVisibility);
    }
  }, []);

  return (
      <div className="fixed bottom-4 right-4 sm:bottom-8 sm:right-8 z-[99] text-right ">
        {/* <JoinTelegramGroupButton /> */}


        <button
          type="button"
          onClick={scrollToTop}
          className={classNames(
            visible ? 'block' : 'hidden',
            'opacity-90 bg-slate-600 hover:bg-slate-400 focus:ring-slate-300 inline-flex items-center rounded-full p-3 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2',
          )}
        >
          <BiArrowFromBottom className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
  );
}
