/* eslint-disable react/display-name */
import { Disclosure, Menu } from '@headlessui/react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import MenuIcon from '../icons/MenuIcon';
import XMenuIcon from '../icons/XMenuIcon';
import PopoverMenu from '../PopoverMenu';
import ThemeSwitch from '../ThemeSwitch';
import { headerData } from './headerData';

import { useAppContext } from '@/context/appProvider';
import { getFirstLetter } from '@/lib/utils';
import { signIn } from 'next-auth/react';
import { forwardRef, useEffect, useState } from 'react';
import HeaderMenu from './mobile/headerMenu';
import { signOut } from 'next-auth/react';
import { clearCookies } from '@/lib/cookies';
import LanguageSwitch from '../LanguageSwitch';
import Cookies from 'js-cookie';

const MyLink = forwardRef((props: any, ref) => {
	const { href, children, ...rest } = props || {};
	return (
		<Link href={href} legacyBehavior>
			<a ref={ref as any} {...rest}>
				{children}
			</a>
		</Link>
	);
});

// const socialLinks = [
// 	{
// 		name: 'Facebook',
// 		href: siteMetadata.facebook,
// 		icon: (props) => (
// 			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
// 				<path
// 					fillRule="evenodd"
// 					d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
// 					clipRule="evenodd"
// 				/>
// 			</svg>
// 		),
// 	},
// 	{
// 		name: 'Twitter',
// 		href: siteMetadata.twitter,
// 		icon: (props) => (
// 			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
// 				<path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
// 			</svg>
// 		),
// 	},
// 	{
// 		name: 'Telegram Chat',
// 		href: siteMetadata.telegramChanel,
// 		icon: (props) => (
// 			<svg
// 				fill="currentColor"
// 				viewBox="0 0 24 24"
// 				{...props}
// 				xmlns="http://www.w3.org/2000/svg"
// 			>
// 				<path
// 					d="M13.5469 12.638C12.3375 13.7944 11.7213 14.6466 11.2687 15.205C11.9205 15.6009 16.6293 18.4898 18.9828 20C19.2621 13.9086 20.444 7.46193 21 5C16.4379 6.15736 7.09914 10.0761 3 11.8909L8.0431 13.7944C9.55894 12.7925 13.3725 10.4133 16.5 8.91181"
// 					stroke="black"
// 					strokeLinecap="round"
// 					strokeLinejoin="round"
// 				/>
// 			</svg>
// 		),
// 	},
// ];

export default function Header({ page }) {
	const { locale } = useRouter();
	const router = useRouter();
	const { t } = useTranslation(['common']);
	const { user, isLoadingUser, refetchUserProfile } = useAppContext();
	const userAvatar = '';
	// const { data: session, status } = useSession();
	// const [userAvatar, setUserAvatar] = useState('');
	const [profileMenu] = useState([
		{
			id: 'overview',
			href: '/account/overview',
			label: t('common:header.accountOverview'),
		},
		// {
		//   id: 'settings',
		//   href: '/account/settings',
		//   label: t('common:header.accountSettings'),
		// },
		{
			id: 'referral',
			href: '/account/referral',
			label: t('common:header.referrals'),
		},
		{
			id: 'signOut',
			label: t('common:header.signOut'),
			onClick: () => {
				signOut({ redirect: true, callbackUrl: '/login' });
				clearCookies();
				Cookies.remove('access-token', {
					path: '/',
					sameSite: 'Lax',
				});
				Cookies.remove('access-token', {
					path: '/',
					domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
					sameSite: 'Lax',
				});
				refetchUserProfile && refetchUserProfile();
			},
		},
	]);
	const [sticky, setSticky] = useState(true);

	// Turn off user avatar to make consistent with alpha.thedatafi.com layout
	// useEffect(() => {
	// 	if (session && session.user && session.user.image) {
	// 		setUserAvatar(session.user.image);
	// 	}
	// }, [session]);

	useEffect(() => {
		if (page === 'article') {
			setSticky(false);
		} else {
			setSticky(true);
		}
	}, [router]);

	const signInButtonVisible = !isLoadingUser && !user;

	return (
		<Disclosure as="nav" className={cn(' top-0 left-0 z-50', { sticky: sticky })}>
			{({ open, close: closeMenu }) => (
				<header className="inset-x-0 top-0 z-50 bg-bg-header shadow-sm">
					<div className="relative mx-auto flex h-header-xs flex-row items-center justify-between px-4 lg:h-header-md lg:px-8">
						{/* Mobile menu button */}
						<Disclosure.Button
							aria-label="menu"
							className="absolute right-4 inline-flex items-center justify-center text-neutral-50 lg:hidden "
						>
							{open ? (
								<XMenuIcon className="md:hover:fill-primary-50 fill-neutral-50" />
							) : (
								<MenuIcon className="md:hover:fill-primary-50 fill-neutral-50 fill-white" />
							)}
						</Disclosure.Button>

						<div className="flex h-full basis-1/2 flex-row flex-nowrap items-center justify-start md:justify-start">
							<Link href="/" passHref locale={locale} className="inline-block flex-none" legacyBehavior>
								<a title="HomePage" className="relative mr-6 w-24 bg-transparent">
									<Image src="/static/images/thedatafi-logo.png" alt="" priority width={90} height={32} />
								</a>
							</Link>

							<div className="flex-none">
								<nav className="ml-auto mr-4 hidden h-full flex-row flex-nowrap place-items-center lg:flex">
									{headerData.map((item) =>
										item.items ? (
											<PopoverMenu
												key={item.id}
												id={item.id}
												title={item.title}
												items={item.items}
												childrenUrls={item.childrenUrls}
											/>
										) : (
											<div key={item.id} className="inline-block w-full min-w-fit flex-1">
												<Link href={item.href ? item.href : '/'} passHref locale={locale} legacyBehavior>
													<a title={item.title}>
														<button
															aria-label="button"
															className={cn(
																{
																	'opacity-100': item.href === router.pathname,
																},
																'md:hover:text-primary-50 h-full w-full flex-auto items-center justify-center px-3 py-1.5 text-sm font-medium text-white opacity-90 hover:opacity-100 focus-visible:outline-none dark:text-gray-100',
															)}
														>
															{t(`common:header.${item.title}`)}
														</button>
													</a>
												</Link>
											</div>
										),
									)}
								</nav>
							</div>
						</div>

						<div className="ml-auto hidden h-full basis-1/2 flex-row flex-nowrap items-center justify-end gap-4 lg:flex">
							{/* <SearchBox /> */}

							<ThemeSwitch />

							<LanguageSwitch />

							{user && (
								<div className="relative z-50">
									<Menu>
										<Menu.Button className="mt-1.5 h-10 w-10 rounded-full !bg-gray-500">
											{userAvatar ? (
												<Image src={userAvatar} width="38" height="38" alt="avatar" className="w-10 rounded-full" />
											) : (
												<span className="inline-flex h-10 w-10 items-center justify-center">
													<span className="text-lg font-medium leading-none text-white">
														{getFirstLetter(user?.username)}
													</span>
												</span>
											)}
										</Menu.Button>

										<Menu.Items
											as="ul"
											className="absolute right-0 mt-1 flex flex-col rounded-sm bg-white text-gray-500 shadow-md dark:bg-gray-700 dark:text-gray-200"
										>
											{profileMenu.map((pMenu) => (
												/* Use the `active` state to conditionally style the active item. */
												<Menu.Item key={pMenu.id} as="li">
													{pMenu.href ? (
														<MyLink
															className="block w-60 px-4 py-3 text-sm opacity-100 hover:opacity-80"
															href={pMenu.href}
														>
															{pMenu.label}
														</MyLink>
													) : (
														<button
															className="block w-60 px-4 py-3 text-left text-sm opacity-100 hover:opacity-80"
															onClick={pMenu.onClick}
														>
															{pMenu.label}
														</button>
													)}
												</Menu.Item>
											))}
										</Menu.Items>
									</Menu>
								</div>
							)}

							{signInButtonVisible && (
								<Link href="#" passHref legacyBehavior>
									<a title="Sign-in">
										<button
											aria-label="button"
											className={cn(
												'active:text-primary-60 md:hover:text-primary-50 ml-4 h-full w-28 flex-auto items-center justify-center rounded-md rounded-sm bg-white px-4 py-1.5 text-sm font-semibold opacity-100  hover:opacity-90 focus-visible:outline-none dark:bg-bg-primary dark:text-gray-100',
											)}
											onClick={() => signIn()}
										>
											{t('common:header.signIn')}
										</button>
									</a>
								</Link>
							)}

							{isLoadingUser && (
								<div className="ml-4">
									<svg
										aria-hidden="true"
										className="mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								</div>
							)}
						</div>
					</div>

					<Disclosure.Panel className="lg:hidden">
						<HeaderMenu
							user={user}
							headerData={headerData}
							t={t}
							signIn={signIn}
							profileMenu={profileMenu}
							closeMenu={closeMenu}
						/>
					</Disclosure.Panel>
				</header>
			)}
		</Disclosure>
	);
}
