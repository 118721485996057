import { baseUrl } from '@/lib/constants';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonScrollTop from './ButtonScrollTop';
import Footer from './Footer';
import Header from './Header';

type ContainerProps = {
	title?: string;
	description?: string;
	images?: string[];
	blank?: boolean;
	children?: ReactNode;
	additionalLinkTags?: object[];
	openGraph?: any;
	page?: string;
};
export default function Container({ children, blank = false, ...props }: ContainerProps) {
	const router = useRouter();
	const { t } = useTranslation(['common']);
	const { pathname } = router;

	const {
		title = t('common:app.title') as string,
		description = t('common:app.description') as string,
		additionalLinkTags = [],
		openGraph = {},
		page = '',
		...restProps
	} = props;

	const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
	const scrollToTopVisible = !pathname.includes('/dashboard');

	return (
		<>
			<NextSeo
				noindex={!isProduction}
				nofollow={!isProduction}
				title={title}
				titleTemplate={`%s | ${process.env.NEXT_PUBLIC_APP_NAME}`}
				defaultTitle={title}
				description={description}
				additionalLinkTags={additionalLinkTags as any}
				canonical={`${baseUrl}${router.asPath}`}
				openGraph={Object.assign(
					{
						url: `${baseUrl}${router.asPath}`,
						type: 'website',
						site_name: process.env.NEXT_PUBLIC_APP_NAME,
						description,
						title: title || process.env.NEXT_PUBLIC_APP_NAME,
						images: [
							{
								url: `${baseUrl}/static/images/thedatafi-cover.webp`,
								alt: title || process.env.NEXT_PUBLIC_APP_NAME,
								width: 790,
								height: 415,
							},
						],
					},
					openGraph,
				)}
				twitter={{
					cardType: 'summary_large_image',
					site: '@TheDataFi',
					handle: '@TheDataFi',
				}}
				{...restProps}
			/>
			{blank ? null : <Header page={page} />}

			<main className="mt-header-mobile desktop:mt-header-desktop min-h-[calc(100vh-134px)] w-full">{children}</main>

			{scrollToTopVisible && <ButtonScrollTop />}

			{blank ? null : <Footer />}
		</>
	);
}
